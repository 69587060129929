<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <h2 class="purple bold">Criar registro</h2>
      </b-col>
      <b-col class="text-right"> </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <b-form-group label="Aluno">
          <SelectSimple
            v-if="studentsOptions && studentsOptions.length > 0"
            v-model="searchUser"
            :callback="selectStudent"
            :options="studentsOptions"
          />
          <b-input-group-text
            class="clear-fields"
            v-if="searchUser != ''"
            v-b-tooltip.hover
            title="Limpar Pesquisa"
          >
            <b-icon icon="x" @click="clearFields" />
          </b-input-group-text>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group class="calendar">
          <label>Data</label>
          <Datepicker
            v-model="dateAux"
            placeholder="Selecione"
            format="dd/MM/yyyy"
            :use-utc="true"
            :language="languages[language]"
            @input="dateChanged = true"
          >
          </Datepicker>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <StandardInput
          v-model="timeAux"
          type="time"
          name="designation"
          label="Hora"
        >
        </StandardInput>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-form-group class="group-radio">
          <label for="label" class="mb-4">Ação</label>
          <div class="radio-inline">
            <b-form-radio v-model="type" name="options" value="presence">
              Presença
            </b-form-radio>
            <b-form-radio v-model="type" name="options" value="regular_leave">
              Saída Regular
            </b-form-radio>
            <b-form-radio v-model="type" name="options" value="absence">
              Falta
            </b-form-radio>
            <b-form-radio v-model="type" name="options" value="late">
              Chegada tardia
            </b-form-radio>
            <b-form-radio v-model="type" name="options" value="antecipated">
              Saída antecipada
            </b-form-radio>
          </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <div class="w-100 mt-5"></div>
      <b-col md="3">
        <b-button
          class="large blue outline"
          :to="{ name: redirectToCorrectUrl('faltas-presencas') }"
          >Voltar</b-button
        >
      </b-col>
      <b-col md="3">
        <b-button
          :disabled="!this.searchUser"
          @click="create"
          class="large blue"
          >Criar</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SelectSimple from "@/components/inputs/SelectSimple.vue";
import DataService from "@/services/students.service";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import StandardInput from "@/components/inputs/StandardInput.vue";
import moment from "moment-timezone";
import httpRda from "../../../http-rda";
import { mapGetters } from "vuex";

export default {
  name: "registrar-faltas-presencas",
  components: {
    Datepicker,
    SelectSimple,
    StandardInput,
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getAuthData",
    }),
  },

  data() {
    return {
      searchUser: "",
      targetDetails: null,
      selectedTargetDetails: null,
      studentsOptions: null,
      attendance: {
        student_id: "",
        date: "",
        type: "",
      },

      dateAux: moment().format("YYYY-MM-DD").toString(),
      timeAux: moment().format("HH:mm").toString(),
      type: "",

      dateChanged: false,

      language: "ptBR",
      languages: lang,

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Faltas e presenças",
          active: true,
        },
      ],
    };
  },

  methods: {
    redirectToCorrectUrl(url) {
      if (
        this.currentUser.role &&
        this.currentUser.role.includes("attendance_manager")
      ) {
        return "registro-" + url;
      } else {
        return url;
      }
    },

    isValid() {
      return (
        this.searchUser.id &&
        this.attendance.date &&
        this.attendance.time &&
        this.attendance.type
      );
    },
    create() {
      let customDate;

      if (this.dateChanged) {
        customDate = moment(this.dateAux).add(1, "day").format("YYYY-MM-DD");

        this.dateAux = moment
          .tz(
            customDate + " " + this.timeAux,
            "YYYY-MM-DD HH:mm",
            "America/Sao_Paulo"
          )
          .utc();
      } else {
        customDate = this.dateAux + " " + this.timeAux;

        this.dateAux = moment
          .tz(
            this.dateAux + " " + this.timeAux,
            "YYYY-MM-DD HH:mm",
            "America/Sao_Paulo"
          )
          .utc();
      }

      const data = {
        student_id: this.searchUser.id,
        date: this.dateAux,
        type: this.type,
      };

      DataService.createStudentAttendance(data)
        .then((response) => {
          this.$router.push({
            name: this.redirectToCorrectUrl("faltas-presencas"),
          });
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Registro criado com sucesso!",
          });

          return response.data;
        })
        .catch((error) => {
          const errorMessage = error.response.data;

          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: errorMessage.error,
            footer: "",
          });

          this.$router.push({
            name: this.redirectToCorrectUrl("faltas-presencas"),
          });
          return error.response;
        });
    },

    selectStudent(params) {
      this.searchUser = params;
    },

    async getStudents() {
      this.targetDetails = await httpRda
        .get("/students-for-attendances")
        .then((response) => response.data.students);

      this.studentsOptions = this.targetDetails.map((student) => ({
        id: student.id,
        label: student.name,
      }));
    },

    clearFields() {
      this.searchUser = "";
    },
  },

  watch: {
    attendances: {
      handler: function (newValue) {
        if (newValue.type === "absence") {
          this.attendances.arrival = "";
          this.attendances.departure = "";
        } else if (newValue.type === "late") {
          this.attendances.departure = "";
        } else if (newValue.type === "antecipated") {
          this.attendances.arrival = "";
        }
      },
      deep: true,
    },
  },

  created() {
    this.getStudents();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.tags {
  background-color: $blue;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  max-width: 58px;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
</style>
